export default {
  appname: "XVR",
  slogan: "Focus on Metaverse Tourism",
  home: "Home",
  profile: "Profile",
  search: "Search...",
  no_more: "No more",
  ai_npc: "AI NPC",
  pulling_text: "Pull down to refresh...",
  loosing_text: "Release to refresh...",
  loading_text: "Loading...",
  back: "Back",
  guest: "Guest",
  loginned_phone: "Loginned phone",
  my_tickets: "My Tickets",
  my_collection: "My Collection",
  help: "Help",
  customer_service: "Customer Service",
  about_xvr: "About XVR",
  logout: "Logout",
  online_service: "Online Service",
  no_customer_service: "The system has not set up customer service",
  no_content: "No content yet",
  privacy_policy: "Privacy Policy",
  user_agreement: "User Agreement",
  forbidden: "You do not have permission to access the current page",
  notfound: "The page you are looking for does not exist",
  agree_all: "Agree to this platform's user agreement and privacy policy",
  sms_login: "SMS Login",
  sms_login_tips: "Unregistered mobile phone numbers will be automatically registered",
  placeholder_phone_input: "Please enter the phone number",
  placeholder_smscode_input: "Please enter a 4-8 digit code",
  phone_number_error:"the phone number is incorrect",
  smscode_error:"Incorrect verification code",
  invitation_code: "Invitation code",
  login: "Login",
  login_register: "Login/Register",
  send_sms_code: "Send",
  sended: "Sended",
  resend_countdown: "Wait {seconds} seconds",
  send_failed: "Failed to send verification code",
  need_agreement: "Please agree to the user agreement first",
  password_error: "Please enter a password of at least 8 characters.",
  welcome_login: "Welcome",
  valid_tickets: "Valid tickets",
  expired_tickets: "Expired Tickets",
  used: "Used",
  unused: "Unused",
  check: "Check",
  my_favorite: "My favorite",
  like: "Like",
  favorite: "Favorite",
  liked: "Liked",
  favorited: "Favorited",
  view_my_tickets: "View My Tickets",
  waiting_for_results: "Waiting for results",
  spot_introduction: "Digital Attractions Introduction",
  show_more: "Show more",
  show_less: "Show less",
  terms_and_conditions: "Terms and Conditions",
  more_scenic_spot: "More",
  buy_with_price: "Buy tickets {price} yuan",
  auto_refund_tips: "Automatic refund if not used within 24 hours",
  play_time_limit: "Play time limit",
  begin_time: "Begin Time",
  hour: "one hour | {count} hours",
  ticket_checking_station: "Ticket Checking Station",
  payment_method: "Payment Method",
  alipay: "Alipay",
  wechatpay: "WeChat Pay",
  paypal: "PayPal",
  payment_tool_tips: "The current environment only supports {payment_tool}",
  payment_handling_fees_tips: "Additional fees",
  phone_number: "Phone Number",
  has_logged: "Has logged",
  copy_link: "Copy Link",
  copy_successful: "Copied",
  copy_failed: "Copy Failed",
  price: "Ticket Price",
  confirm_pay: "Confirm Pay",
  front_end_error: "Front-end script exception",
  front_end_error2: "Front-end script exception/page signature failure",
  payment_successful: "Payment successful",
  payment_failed: "Payment failed",
  payment_cancelled: "You have cancelled your payment",
  ticket_code: "Code",
  rate: "Rate",
  favorite_successful: "Successful",
  favorite_canceled: "Canceled",
  like_successful: "Successful",
  like_canceled: "Canceled",
};
